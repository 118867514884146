.message-row {
  cursor: pointer;
}

.message-list-container {
  max-height: 50vh;
}

.sort-icon {
  cursor: pointer;
}

.white-bg-button {
  background-color: white;
}